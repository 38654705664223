<template>
  <div class="space-y-4">
    <label v-for="option in options" :key="option.value" :class="['flex items-center space-x-2 p-4 border', selected === option.value ? 'border-blue-500' : 'border-gray-800']"
        @click="updateSelected(option.value)">
      <input
        type="radio"
        :value="option.value"
        :name="name"
        class="h-5 w-5 text-blue-400 focus:ring-blue-500"
        :checked="selected === option.value ? true : false"
      />
      <span class="text-gray-700 text-sm font-bold cursor-pointer">{{ option.title }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'RadioSelect',
  props: {
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      default: 'radio-group',
    },
    selected: {
      type: String,
      default: '',
    },
  },
  methods: {
    updateSelected(newValue) {
      this.$emit('update:radioSelected', newValue)
    }
  }
};
</script>